/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { rhythm } from '../utils/typography';
import Tag from './tag';
import TimetoRead from './time-to-read';
import { useLayoutContext } from '../context/layout-context';

const ArticleList = ({ posts }) => {
  const { view } = useLayoutContext();

  const display = view === 'grid'
    ? {
      display: 'grid',
      gap: '2rem',
      gridTemplateColumns:
            'repeat(auto-fill, minmax(min(100%, 30ch), 1fr))',
      /* The following trick is copied from
           * Ref: https://css-tricks.com/full-width-containers-limited-width-parents/
           */
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginInlineStart: '-50vw',
      marginInlineEnd: '-50vw',
      paddingInline: '2rem',
    }
    : {
      /* This is to nullify prism css margin left style for ul */
      marginInlineStart: 0,
    };

  return (
    <ul css={display}>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <li key={node.fields.slug}>
            <Article>
              <h2
                css={`
                  margin-top: ${rhythm(1 / 4)};
                  margin-bottom: ${rhythm(1 / 4)};
                `}
              >
                <TitleLink to={node.fields.slug}>{title}</TitleLink>
              </h2>
              <small>{node.frontmatter.date}</small>
              <TimetoRead time={node.frontmatter.timeToRead} />
              {node.frontmatter.tags?.length && (
                <>
                  <h3
                    css={`
                      height: 0;
                      width: 0;
                      overflow: hidden;
                      margin: 0;
                    `}
                    id="article-tags"
                  >
                    Article tags
                  </h3>
                  <ul
                    aria-labelledby="article-tags"
                    css={`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 0.5rem;
                      margin-block-end: 1rem;
                      margin-inline: 0;
                      & > li {
                        margin-block-end: 0;
                      }

                      li a {
                        padding-inline-end: 0.5rem;
                        padding-block: 0.5rem;
                      }

                      li:first-child a {
                        padding-inline-start: 0;
                      }
                    `}
                  >
                    {node.frontmatter.tags?.map((tag) => (
                      <li key={tag}>
                        <Tag to={`/tags/${tag}`}>
                          <span aria-hidden>#</span>
                          {tag}
                        </Tag>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <p
                css={`
                  margin-top: ${rhythm(1 / 5)};
                `}
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </Article>
          </li>
        );
      })}
    </ul>
  );
};

export default ArticleList;

/*
 * Styled components -------------------
 */

const Article = styled.article`
  color: var(--text-color);
  background: var(--card-box-bg);
  position: relative;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--card-box-shadow-sm);
  padding-block: 1.75rem;
  padding-inline: 1.5rem;
  margin-bottom: 3rem;

  /* to fill the grid area vertically */
  height: 100%;

  transition: transform 0.2s ease, box-shadow 0.2s ease;
  will-change: transform, box-shadow;
  & p {
    margin-bottom: 0.75rem;
  }
  &:hover {
    box-shadow: var(--card-box-shadow-md);
    transform: translateY(-5px) translateZ(0);
  }

  a:focus {
    outline: 2px solid;
  }

  :focus-within {
    --focus-outer-color: var(--secondary-color-dark);
    box-shadow: 0 0 0 0.075rem var(--focus-inner-color),
      0 0 0 0.2rem var(--focus-outer-color);
  }

  /* if browser supports :focus-within we remove unnecessary focus on descedant a */
  :focus-within a:focus {
    text-decoration: none;
    outline: none;
  }

  @media screen and (min-width: 1024px) {
    padding-block: 2rem;
    padding-inline: 2.4rem;
  }
`;

/* Title link of the post card */
const TitleLink = styled(Link)`
  /* To override the blog theme */
  box-shadow: none;

  color: var(--card-link-color);
  cursor: pointer;
  text-decoration: none;
  font-weight: var(--font-weight-bold);

  /* Make link element covers the whole card */
  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
  &:hover {
    color: var(--card-link-hover);
    transition: color 0.2s linear;
  }
`;
