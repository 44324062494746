/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
import { useEffect } from 'react';

/*
  this hook checks if URL has search query,
  and insert that search query in input field
*/
const useSearchQueryParam = (inputRef) => {
  useEffect(() => {
    const searchUrl = new URLSearchParams(window.location.search);
    const searchStr = searchUrl.get('q');
    if (searchStr) {
      inputRef.current.value = searchStr;
    }
  }, []);
};

export default useSearchQueryParam;
