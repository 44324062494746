const STOP_WORDS = [
  'a',
  'an',
  'and',
  'are',
  "aren't",
  'as',
  'by',
  'can',
  'cannot',
  "can't",
  'could',
  "couldn't",
  'is',
  "isn't",
  'it',
  'its',
  "it's",
  'that',
  'the',
  'their',
  'there',
  'they',
  "they're",
  'them',
  'to',
  'too',
  'us',
  'very',
  'was',
  'we',
  'well',
  'were',
  'what',
  'whatever',
  'when',
  'whenever',
  'where',
  'with',
  'would',
  'yet',
  'you',
  'your',
  'yours',
  'yourself',
  'yourselves',
  'the',
];

export default STOP_WORDS;
