import React from 'react';
import styled from 'styled-components';
import { useLayoutContext } from '../context/layout-context';
import { GridIcon, ListIcon } from './icon';
import VisuallyHidden from './visually-hidden';

/*
 * Using button group to construct a segmented control UI
 */
const ToggleLayout = () => {
  const { view, setView } = useLayoutContext();
  return (
    <Wrapper role="group">
      <Button type="button" onClick={() => setView('list')} isActive={view === 'list'}>
        <VisuallyHidden>Switch to List view</VisuallyHidden>
        <ListIcon size="small" />
      </Button>
      <Button type="button" onClick={() => setView('grid')} isActive={view === 'grid'}>
        <VisuallyHidden>Switch to Grid view</VisuallyHidden>
        <GridIcon size="small" />
      </Button>
    </Wrapper>
  );
};

const Button = styled.button`
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  font-size: 0.8rem;
  --radius: var(--border-radius-lg);

  cursor: pointer;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  color: ${(props) => (props.isActive ? 'var(--primary-color)' : 'none')};

  :focus,
  :active {
    background-color: hsla(var(--pallete-gray-90));
    color: var(--primary-color);

    /* Focus ring for accessibility purposes */
    outline: none;
    box-shadow: 0 0 0 0.075rem var(--focus-inner-color), 0 0 0 0.2rem var(--focus-outer-color);
  }

  :hover {
    background-color: hsla(var(--pallete-gray-70));
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  border: 1px solid var(--text-color);
  & button:not(:last-child) {
    border-right: 2px solid var(--text-color);
  }
  border-color: var(--text-color);
  border-radius: var(--border-radius-md);

  & button:first-of-type {
    border-top-left-radius: var(--border-radius-md);
    border-bottom-left-radius: var(--border-radius-md);
  }
  & button:last-child {
    border-top-right-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
`;

export default ToggleLayout;
