/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef } from 'react';
import { srOnly } from '../utils/css';
import { rhythm } from '../utils/typography';
import { SearchIcon } from './icon';

const SearchPost = forwardRef(({ handleInputChange }, ref) => (
  <form
    action="https://duckduckgo.com/"
    method="get"
    id="form-search"
    role="search"
    autoComplete="off"
    spellCheck="false"
    onSubmit={(event) => {
      /* We are using form native search functionality via `action`
     and `method` attributes. So, that functionality will even work when JS
     fails to load.
     But when JS loads OK, we prevent the default behaviour (searching DuckDuckGo)
     */
      event.preventDefault();
    }}
    css={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.5rem',
      '& input:focus ~ button svg': {
        color: 'var(--primary-color)',
      },
    }}
  >
    <label htmlFor="input-search" css={srOnly}>
      Search for:
    </label>
    <input
      ref={ref}
      type="text"
      name="q"
      id="input-search"
      onChange={handleInputChange}
      placeholder="Type to search articles..."
      enterKeyHint="search"
      css={{
        width: '85%',
        border: '1px solid var(--neutral-light)',
        borderRadius: 'var(--border-radius-xlg)',
        paddingInlineEnd: rhythm(1),
        paddingInlineStart: rhythm(0.5),
        paddingBlock: rhythm(0.2),
      }}
    />
    <input type="hidden" name="sites" value="amanexplains.com" />
    <button
      aria-labelledby="search-button"
      type="submit"
      css={{
        border: 'none',
        background: 'none',
        marginLeft: '-2.75rem',
        borderRadius: 'var(--border-radius-lg)',
        paddingInlineStart: '0.5rem',

        '&:focus, &:active': {
          color: 'var(--primary-color)',
          /* Focus ring for accessibility purposes */
          outline: 'none',
          boxShadow:
            '0 0 0 0.075rem var(--focus-inner-color), 0 0 0 0.125rem var(--focus-outer-color)',
        },
      }}
    >
      <span id="search-button" hidden>
        Search
      </span>
      <SearchIcon size="small" />
    </button>
  </form>
));

export default SearchPost;
