import React from 'react';
import { Link } from 'gatsby';

const Tag = (props) => (
  <Link
    css={`
      display: inline-block;
      font-size: 80%;
      box-shadow: none;
      line-height: 1;
      text-decoration: none;
      text-transform: captitalize;
      font-weight: var(--font-weight-normal);
      z-index: 1;
      position: relative;

      &:active,
      &:hover {
        color: var(--primary-color);
        font-weight: var(--font-weight-semibold);
      }
      :active {
        transform: scale(0.99);
      }

      &:focus {
        --focus-outer-color: var(--secondary-color-dark);
        box-shadow: 0 0 0 0.075rem var(--focus-inner-color),
          0 0 0 0.2rem var(--focus-outer-color);
      }
    `}
    {...props}
  />
);

export default Tag;
