import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { rhythm } from '../utils/typography';

import { ProfileListHeader } from './profile-list';

function Bio({ hasProfile = true, ...props }) {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { author, social } = data.site.siteMetadata;
        return (
          <>
            <Container {...props}>
              <GatsbyImage
                image={data.avatar.childImageSharp.gatsbyImageData}
                alt={author}
                css={{
                  marginRight: rhythm(1 / 2),
                  marginBottom: 0,
                  minWidth: 50,
                  borderRadius: '100%',
                  height: 50,
                  width: 50,
                  gridArea: 'avatar',
                }}
              />
              <p
                css={{
                  color: 'var(--text-color)',
                  gridArea: 'info',
                  marginBlockEnd: 0,
                }}
              >
                Written by&nbsp;
                <a href={`https://twitter.com/${social.twitter}`}>
                  <strong>{author}</strong>
                </a>
                . Developer @ &nbsp;
                <a href="https://avarni.co/">Avarni</a>
                &nbsp; Sydney. Tech enthusiast and a pragmatic programmer.
              </p>
              {hasProfile && <ProfileListHeader style={{ gridArea: 'profile' }} />}
            </Container>
          </>
        );
      }}
    />
  );
}

const Container = styled.div`
  /* mobile first */
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;
  place-items: center;
  margin-block-end: 3.75rem;
  grid-template-areas:
    "avatar"
    "info"
    "profile";

  text-align: center;
  @media screen and (min-width: 624px) {
    margin-block-end: 2.5rem;
    grid-template-columns: minmax(50px, min-content) 1fr;
    place-items: start;
    text-align: initial;
    grid-template-areas:
      "avatar info"
      "... profile";
  }
`;

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 50, height: 50, quality: 90)
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;
