/* eslint-disable no-multi-assign */
/* eslint-disable consistent-return */
import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { rhythm } from '../utils/typography';
import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ToggleLayout from '../components/toggle-layout';
import ArticleList from '../components/article-list';
import SearchPost from '../components/search-post';
import search, { updateURL } from '../utils/search';
import debounce from '../utils/debounce';
import useSearchQueryParam from '../components/hooks/use-search-query-param';

const DEBOUNCE_DELAY = 250;

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const allPosts = data.allMdx.edges;

  const inputRef = useRef();

  const [state, setState] = useState({
    filteredData: null,
    query: '',
  });

  useSearchQueryParam(inputRef);

  const handleInputChange = (event) => {
    const query = event.target.value;

    const filteredData = search(query);
    // update state according to the latest query and results
    setState({
      query,
      filteredData,
    });

    updateURL(query);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(handleInputChange, DEBOUNCE_DELAY),
    [],
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search).get('q');
    if (!query) return;
    inputRef.current.value = query;

    const filteredData = search(query);
    // update state according to the latest query and results
    setState({
      query,
      filteredData,
    });
  }, []);

  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query;
  const posts = hasSearchResults ? filteredData : allPosts;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="All posts"
        keywords={['blog', 'javascript', 'react', 'accessibility']}
        description="A personal blog by Amandeep singh. He loves writing about React, JavaScript, Accessibility, Dev tips, and Web fundamentals."
      />
      <Bio />
      <SearchPost handleInputChange={debouncedChangeHandler} ref={inputRef} />
      <div role="status" css={{ textAlign: 'center' }}>
        {hasSearchResults && (
          <p css={{ marginBottom: '0.2rem' }}>
            Found&nbsp;
            {filteredData.length}
&nbsp; articles matching &apos;
            {query}
            &apos;
          </p>
        )}
        {hasSearchResults && (
          <p>
            If results are not good, you can &nbsp;
            <a href="/tags">try searching for all tags.</a>
          </p>
        )}
      </div>
      <Container>{posts.length > 0 && <ToggleLayout />}</Container>
      <ArticleList posts={posts} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            timeToRead
            tags
            description
          }
        }
      }
    }
  }
`;

// Toggle layout container
const Container = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    flex-direction: column;
    position: sticky;
    top: 1rem;
    z-index: 1;
    display: flex;
    align-items: center;
    margin-block-end: ${rhythm(1 / 2)};
  }
`;
