import React from 'react';

import { scale } from '../utils/typography';

function TimetoRead({ time = 5 }) {
  return (
    <small
      css={`
        ${scale(-1 / 3)};
        padding-inline-start: 0.25rem;
        color: var(--text-small-color);
      `}
    >
      •&nbsp;
      {Array(Math.ceil(time / 5))
        .fill(null)
        .map(() => '☕️')}
      &nbsp;
      {time}
      {' '}
      min read
    </small>
  );
}

export default TimetoRead;
