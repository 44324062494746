/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
import SEARCH_INDEX from './search-index';
import STOP_WORDS from './stop-words';

/**
 * This search functionality is built using
 * https://gomakethings.com/how-to-create-a-search-page-for-a-static-website-with-vanilla-js/ as a ref
 *
 * Search for matches
 * @param  {String} query The term to search for
 */
export default function search(query) {
  // Create a regex for each query
  const regMap = query
    .toLowerCase()
    .split(' ')
    .filter((word) => word.length && !STOP_WORDS.includes(word))
    .map((word) => new RegExp(word, 'i'));

  // Get and sort the results
  const results = SEARCH_INDEX.reduce((acc, post) => {
    const {
      node: {
        frontmatter: { title, tags, description },
      },
    } = post;

    // Setup priority count
    let priority = 0;

    // Assign priority
    for (const reg of regMap) {
      if (reg.test(title)) {
        priority += 100;
      }

      if (reg.test(tags)) {
        priority += 50;
      }
      const occurences = description.match(reg);
      if (occurences) {
        priority += occurences.length;
      }
    }

    // If any matches, push to results
    if (priority > 0) {
      acc.push({
        priority,
        post,
      });
    }

    return acc;
  }, [])
    .sort((post1, post2) => post2.priority - post1.priority)
    .map((res) => res.post);

  return results;
}

/**
 * This following function has been taken from Chris ferdinandi blog
 * article: https://gomakethings.com/how-to-update-the-url-of-a-page-without-causing-a-reload-using-vanilla-javascript/#updating-the-url
 * Update the URL with a query string for the search string
 * @param  {String} query The search query
 */
export function updateURL(query) {
  // Update search string in URL
  if (query.length > 0) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('q', query);
    const newRelativePathQuery = `${
      window.location.pathname
    }?${searchParams.toString()}`;
    history.replaceState(null, '', newRelativePathQuery);
  } else {
    history.replaceState(null, '', window.location.pathname);
  }
}
